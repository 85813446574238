/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useMemo, useState} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../pages/auth'
import {ErrorsPage} from '../pages/errors/ErrorsPage'
import {RootState} from '../../setup'
import ModalContext from '../components/tickets/ModelContext'
import {AddLoteProvider} from '../components/lotes/AddLoteContext'
import GetExternalComponent from '../components/UI/GetExternalComponent'

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [isOpen, setIsOpen] = useState(false)

  function toggleModal() {
    setIsOpen(!isOpen)
  }

  const layout = useMemo(() => {
    return (
      <MasterLayout>
        <PrivateRoutes />
      </MasterLayout>
    )
  }, [])

  const store = useMemo(() => ({isOpen, toggleModal}), [isOpen])
  const isExternal = window.location.href.includes('/external')

  return (
    <Switch>
      {isExternal ? (
        <Route path='/external' component={GetExternalComponent} />
      ) : (
        <>
          {!isAuthorized ? (
            /*Render auth page when user at `/auth` and not authorized.*/
            <Route>
              <AuthPage />
            </Route>
          ) : (
            /*Otherwise redirect to root page (`/`)*/
            <ModalContext.Provider value={store}>
              <AddLoteProvider>{layout}</AddLoteProvider>
            </ModalContext.Provider>
          )}

          <Route path='/error' component={ErrorsPage} />
          <Route path='/logout' component={Logout} />
        </>
      )}
    </Switch>
  )
}

export {Routes}
