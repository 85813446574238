import React, {createContext, useContext, useMemo, useReducer} from 'react'
import {MaterialDeControlDTO} from '../../models/DTOs/parametrizacion-general/materiales-de-control/MaterialDeControlDTO'

// const AddLoteContext = React.createContext<{
//   isAddLoteOpen: boolean
//   toggleAddLote?: () => void
//   materialActual: MaterialDeControlDTO | undefined
//   setMaterial?: (materialControl: MaterialDeControlDTO) => void
// }>({
//   isAddLoteOpen: false,
//   materialActual: undefined,
// })

// export default AddLoteContext

export interface AddLoteState {
  isAddLoteOpen: boolean
  materialControl: MaterialDeControlDTO | undefined
}

const initialState = {
  isAddLoteOpen: false,
  materialControl: undefined,
}

const AddLoteContext = createContext<AddLoteState | any>(initialState)

interface ReducerAction {
  type: string
  payload?: any
}

const actionTypes = {
  SetMaterialControl: 'Set Material Control',
  OpenModal: 'Open Modal',
  CloseModal: 'Close Modal',
}

const reducer = (state: AddLoteState, action: ReducerAction): AddLoteState => {
  switch (action.type) {
    case actionTypes.OpenModal:
      return {...state, isAddLoteOpen: true}
    case actionTypes.CloseModal:
      return {...state, isAddLoteOpen: false}
    case actionTypes.SetMaterialControl:
      return {...state, materialControl: action.payload}
    default:
      return {...state}
  }
}

export const AddLoteProvider = (props: React.PropsWithChildren<any>) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setMaterialControl = (materialControl: MaterialDeControlDTO) =>
    dispatch({type: actionTypes.SetMaterialControl, payload: materialControl})

  const openModal = () => dispatch({type: actionTypes.OpenModal})

  const closeModal = () => dispatch({type: actionTypes.CloseModal})

  const value = useMemo(() => ({...state, setMaterialControl, openModal, closeModal}), [state])

  return <AddLoteContext.Provider value={value} {...props} />
}

export const useAddLoteContext = () => {
  const context = useContext(AddLoteContext)
  if (context === undefined) {
    throw new Error(`useAddLoteContext must be used within an AddLoteProvider`)
  }
  return context
}
