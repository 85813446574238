import {nanoid} from '@reduxjs/toolkit'
import {ChangeEvent, FC, useEffect, useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {showError, toastError} from '../../../helpers/AlertHelper'
import {errorMessageBuilder} from '../../../helpers/error-message-builder'
import {SectorDTO} from '../../../models/DTOs/sectores/SectorDTO'
import {RootState} from '../../../../setup'
import * as auth from '../../../pages/auth/AuthRedux'
import {getSectoresAsync} from '../../../modules/sectores/get-sectores'
import {EstadoType} from '../../../models/DTOs/common/types'
import {useGetLaboratoriosGrupoQuery} from '../../../modules/grupos-laboratorios/getLaboratoriosGrupo'
import {LaboratorioSummaryDTO} from '../../../models/DTOs/laboratorios/LaboratorioSummaryDTO'

type Props = {
  onClose: () => void
}

const ModalLabAndSector: FC<Props> = ({onClose}) => {
  const sectorActual: SectorDTO = useSelector<RootState>(
    ({auth}) => auth.sector,
    shallowEqual
  ) as SectorDTO
  const laboratorioActual: LaboratorioSummaryDTO = useSelector<RootState>(
    ({auth}) => auth.laboratorio,
    shallowEqual
  ) as LaboratorioSummaryDTO

  const [sectores, setSectores] = useState<SectorDTO[]>([])
  const [laboratorios, setLaboratorios] = useState<LaboratorioSummaryDTO[]>([])
  const [selectedLab, setSelectedLab] = useState<string>()
  const [selectedSector, setSelectedSector] = useState<number>()
  const [sectoresLoading, setSectoresLoading] = useState(false)

  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    isSuccess: isULSuccess,
    isError: isULError,
    isFetched: isULFetched,
    data: ULData,
    error: ULError,
    isLoading: laboratoriosLoading,
  } = useGetLaboratoriosGrupoQuery()

  useEffect(() => {
    if (!isULError && !isULSuccess) return

    if (isULSuccess && isULFetched && ULData) {
      setLaboratorios(ULData)
      setSelectedLab(
        ULData.find((l: LaboratorioSummaryDTO) => l.Nombre === laboratorioActual.Nombre).Id
      )
    }
    if (isULError) {
      showError(
        'Error',
        errorMessageBuilder(ULError, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isULError, isULFetched, isULSuccess, ULData])

  useEffect(() => {
    if (!selectedLab) return

    setSectoresLoading(true)

    getSectoresAsync(selectedLab)
      .then((USData) => {
        setSectores(USData)
        setSelectedSector(
          USData.find((s: SectorDTO) => s.Id === sectorActual.Id) ? sectorActual.Id : USData[0].Id
        )
      })
      .catch((USError) => {
        showError(
          'Error',
          errorMessageBuilder(USError, intl.formatMessage),
          intl.formatMessage({id: 'SETTINGS.ACCEPT'})
        )
      })
      .finally(() => setSectoresLoading(false))
  }, [selectedLab])

  const btnAceptarHandler = () => {
    const sector = sectores.find((s) => s.Id === selectedSector)
    const lab = laboratorios.find((l) => l.Id === selectedLab)

    if (!sector || !lab) {
      toastError('Error')
    } else {
      dispatch(auth.actions.setLabAndSector(lab, sector))
      setTimeout(() => {
        window.location.href = `${window.location.origin}/cc`
        onClose()
      }, 2000)
    }
  }
  const onLabChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setSelectedLab(value)
  }

  const onSectorChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setSelectedSector(+value)
  }

  return (
    <Modal show onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{intl.formatMessage({id: 'SETTINGS.SELECT_LAB_AND_SECTOR'})}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {laboratoriosLoading ? (
          <>
            <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />{' '}
            {intl.formatMessage({id: 'SETTINGS.LOADING'})}
          </>
        ) : (
          <>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'MENU.LAB'})}</label>
              <select
                name='cbLab'
                className='form-select'
                onChange={onLabChange}
                value={selectedLab}
              >
                {laboratorios.map((laboratorio) => {
                  return (
                    <option
                      key={nanoid()}
                      value={laboratorio.Id}
                      selected={laboratorioActual.Nombre === laboratorio.Nombre}
                    >
                      {laboratorio.Nombre}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className='mb-3'>
              <label className='form-label'>{intl.formatMessage({id: 'MENU.SECTOR'})}</label>
              {sectoresLoading ? (
                <>
                  <br />
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  />{' '}
                  {intl.formatMessage({id: 'SETTINGS.LOADING'})}
                </>
              ) : (
                <select
                  name='cbSector'
                  className='form-select'
                  onChange={onSectorChange}
                  value={selectedSector}
                >
                  {sectores.map((sector) => {
                    return (
                      sector.Estado === EstadoType.Activo && (
                        <option
                          key={nanoid()}
                          value={sector.Id}
                          selected={sectorActual.Id === sector.Id}
                        >
                          {sector.Nombre}
                        </option>
                      )
                    )
                  })}
                </select>
              )}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light-secondary' className='mx-2' onClick={onClose}>
          {intl.formatMessage({id: 'SETTINGS.CLOSE'})}
        </Button>
        <Button
          type='submit'
          variant='primary'
          className='mx-2'
          name='btnAceptar'
          onClick={btnAceptarHandler}
        >
          {intl.formatMessage({id: 'SETTINGS.ACCEPT'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalLabAndSector
