import Cleave from 'cleave.js/react'
import {useFormik} from 'formik'
import {FC, useContext, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {useGetAllProcedimientosMedidaQuery} from '../../modules/procedimientos-de-medida/get-all-procedimientos-medida'
import {showError, toastSuccess} from '../../helpers/AlertHelper'
import {errorMessageBuilder} from '../../helpers/error-message-builder'
import {useUpsertLoteMutation} from '../../modules/parametrizacion-general/lotes/upsert-lote'
import {ProcedimientoMedidaDTO} from '../../models/DTOs/procedimientos-de-medida/ProcedimientoMedidaDTO'
import {useAddLoteContext} from './AddLoteContext'

const AgregarLote: FC = () => {
  const [cbProcedimientosValue, setCbProcedimientosValue] = useState<
    {value: number; label: string}[]
  >([])
  const [selectOptions, setSelectOptions] = useState<{value: number; label: string}[]>([])

  const intl = useIntl()
  const animacionSelect = makeAnimated()

  const {isAddLoteOpen, materialControl, closeModal} = useAddLoteContext()

  const {
    isSuccess: isProcedimientosSuccess,
    isError: isProcedimientosError,
    isFetched: isProcedimientosFetched,
    data: procedimientosData,
    error: procedimientosError,
    isLoading: isProcedimientosLoading,
  } = useGetAllProcedimientosMedidaQuery()

  const {mutate: createLote, isError, isSuccess, isLoading, error} = useUpsertLoteMutation()

  useEffect(() => {
    if (!isProcedimientosError && !isProcedimientosSuccess) return

    if (isProcedimientosSuccess && isProcedimientosFetched && procedimientosData) {
      if (procedimientosData.IsSuccess) {
        setSelectOptions(
          procedimientosData.Value.map((p: ProcedimientoMedidaDTO) => {
            return {
              label: `${p.Equipo.Descripcion} / ${p.Equipo.Plataforma} | ${p.Mensurando.Analito.Descripcion} / ${p.Mensurando.Matriz.Descripcion} / ${p.Mensurando.Unidad.Descripcion} | ${p.Metodo.Reactivo.Descripcion} / ${p.Metodo.PrincipioMedida.Descripcion}`,
              value: p.Id,
            }
          })
        )
      }
    } else if (isProcedimientosError) {
      showError(
        'Error',
        errorMessageBuilder(procedimientosError, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isProcedimientosError, isProcedimientosFetched, isProcedimientosSuccess, procedimientosData])

  useEffect(() => {
    if (!isSuccess && !isError) return

    if (isSuccess) {
      toastSuccess(intl.formatMessage({id: 'SUCCESS.CREATE.BATCH'}))
      //onSuccess()
      handleCloseModal()
      window.location.reload()
    } else if (isError) {
      showError(
        intl.formatMessage({id: 'ERROR.CREATE.BATCH'}),
        errorMessageBuilder(error, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isSuccess, isError])

  const initialValues = {
    txtNumero: '',
    txtFechaVencimiento: '',
  }

  const validationSquema = Yup.object().shape({
    txtNumero: Yup.string()
      .min(3, intl.formatMessage({id: 'FORMIK.MIN'}).replace('{number}', '3'))
      .max(50, intl.formatMessage({id: 'FORMIK.MAX'}).replace('{number}', '50'))
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'})),
    txtFechaVencimiento: Yup.string()
      .required(intl.formatMessage({id: 'FORMIK.REQUIRED'}))
      .length(10, intl.formatMessage({id: 'FORMIK.INCOMPLETE.DATE'})),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSquema,
    onSubmit: (values) => {
      if (!materialControl) return

      createLote({
        materialId: materialControl.Id,
        numero: values.txtNumero,
        fechaVencimiento: values.txtFechaVencimiento,
        procedimientosId: cbProcedimientosValue.map((p) => p.value),
        isActivo: true,
      })
    },
  })

  const handleCloseModal = () => {
    formik.resetForm()
    closeModal()
  }

  return (
    <Modal show={isAddLoteOpen} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>{`${intl.formatMessage({id: 'HEADER.ADD_BATCH'})} (${
          materialControl?.Nombre
        })`}</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Modal.Body>
          <div className='mb-3'>
            <label className='form-label'>{intl.formatMessage({id: 'HEADER.NUMBER'})}</label>
            <input
              {...formik.getFieldProps('txtNumero')}
              type='text'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid'
            />
            {formik.touched.txtNumero && formik.errors.txtNumero && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.txtNumero}</span>
              </div>
            )}
          </div>
          <div className='mb-3'>
            <label className='form-label'>
              {intl.formatMessage({id: 'HEADER.EXPIRATIONDATE'})}
            </label>
            <Cleave
              {...formik.getFieldProps('txtFechaVencimiento')}
              options={{
                date: true,
                datePattern: ['d', 'm', 'Y'],
              }}
              placeholder='dd/mm/yyyy'
              className='form-control form-control-lg form-control-solid w-50'
              autoComplete='off'
            />
            {formik.touched.txtFechaVencimiento && formik.errors.txtFechaVencimiento && (
              <div className='fv-plugins-message-container text-danger'>
                <span role='alert'>{formik.errors.txtFechaVencimiento}</span>
              </div>
            )}
          </div>
          <div className='mb-3'>
            <label className='form-label'>
              {intl.formatMessage({id: 'MENU.MEASUREMENT_PROCEDURES'})}
            </label>
            {selectOptions.length > 0 ? (
              <>
                <Select
                  isMulti
                  components={animacionSelect}
                  closeMenuOnSelect={false}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: '#d6c6c9',
                      background: 'white',
                      height: 43,
                      borderWidth: 0.1,
                      boxShadow: 'none',
                    }),
                    valueContainer: (base, props) => ({
                      ...base,
                      overflowY: 'scroll',
                      maxHeight: 40,
                    }),
                  }}
                  options={selectOptions}
                  className='basic-multi-select cbProcedimientos'
                  placeholder={intl.formatMessage({id: 'PLACEHOLDER.MEASUREMENT_PROCEDURE/S'})}
                  classNamePrefix='select'
                  onChange={(choices) => {
                    setCbProcedimientosValue(
                      choices.map((c: any) => {
                        return c
                      })
                    )
                  }}
                  value={cbProcedimientosValue}
                />
              </>
            ) : (
              <div>
                <span>{intl.formatMessage({id: 'NOT_FOUND.MEASUREMENT_PROCEDURES'})}</span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='light-secondary' onClick={handleCloseModal} name='btnCerrar'>
            {intl.formatMessage({id: 'SETTINGS.CLOSE'})}
          </Button>
          <Button
            variant='primary'
            name='btnAgregarLote'
            type='submit'
            disabled={formik.isSubmitting || !formik.isValid || isLoading}
          >
            {intl.formatMessage({id: 'SETTINGS.ADD'})}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AgregarLote
