import axios from 'axios'
import ROUTES from '../../../helpers/Routes'
import {useQuery} from 'react-query'

const getMaterialesControlLaboratorio = async () => {
  const {data} = await axios.get(`${ROUTES.MATERIALES_CONTROL}/laboratorio`)

  return data
}

const useGetMaterialesControlLaboratorioQuery = () => {
  return useQuery('useGetMaterialesControlLaboratorio', () => getMaterialesControlLaboratorio())
}

export {useGetMaterialesControlLaboratorioQuery}
