import {FC, useEffect, useState} from 'react'
import {ResultadoDTO} from '../../../models/DTOs/cc-resultados/ResultadoDTO'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import {useIntl} from 'react-intl'
import {EstadoBotellaReactivoType, EstadoResultadoType} from '../../../models/DTOs/common/types'
import {LimiteGraficoControlDTO} from '../../../models/DTOs/limites-de-graficos-de-control/LimiteGraficoControlDTO'
import moment from 'moment'

interface Props {
  resultados: ResultadoDTO[]
  nivelGrafico: number
  limitesGraficosControl: LimiteGraficoControlDTO[]
}

const GraficoControlIDE: FC<Props> = ({resultados, nivelGrafico, limitesGraficosControl}) => {
  const [options, setOptions] = useState<Highcharts.Options>({})

  const intl = useIntl()
  const coloresNiveles = ['#8F1A5D', '#0000FF', '#a80c0c', '#10613b', '#877510', '#944300']
  const formasNiveles = ['circle', 'square', 'diamond']
  const series: Highcharts.SeriesOptionsType[] = []
  const resultadosAceptados = resultados
    .filter(
      (resultado) =>
        resultado.Niveles.find((nivel) => nivel.Numero === nivelGrafico && nivel.IsCompleto) &&
        resultado.Niveles.find((nivel) => nivel.Numero === nivelGrafico)!.Estado !=
          EstadoResultadoType.Rechazado
    )
    .sort((a, b) => {
      return moment(a.Fecha, 'DD/MM/YYYY hh:mm:ss') > moment(b.Fecha, 'DD/MM/YYYY hh:mm:ss')
        ? 1
        : -1
    })
  const resultadosRechazados = resultados
    .filter(
      (resultado) =>
        resultado.Niveles.find((nivel) => nivel.Numero === nivelGrafico && nivel.IsCompleto) &&
        resultado.Niveles.find((nivel) => nivel.Numero === nivelGrafico)!.Estado ==
          EstadoResultadoType.Rechazado
    )
    .sort((a, b) => {
      return moment(a.Fecha, 'DD/MM/YYYY hh:mm:ss') > moment(b.Fecha, 'DD/MM/YYYY hh:mm:ss')
        ? 1
        : -1
    })

  useEffect(() => {
    series.push({
      name: `${intl.formatMessage({id: 'SETTINGS.LEVEL'})} ${nivelGrafico}`,
      type: 'line',
      dashStyle: 'Solid',
      lineWidth: 1,
      color: coloresNiveles[nivelGrafico - 1],
      marker: {
        symbol: formasNiveles[nivelGrafico - 1],
      },
      tooltip: {
        headerFormat: undefined,
        pointFormat: `<strong>${intl.formatMessage({
          id: 'HEADER.DATE',
        })}: </strong>{point.Fecha}<br/>
          <strong>VI: </strong>{point.Valor}<br/>
          <strong>Z: </strong>{point.Z}<br/>
          <strong>${intl.formatMessage({id: 'HEADER.STATUS'})}: </strong>{point.Estado}<br/>
          <strong>${intl.formatMessage({id: 'HEADER.REAGENT'})} ${intl
          .formatMessage({id: 'SETTINGS.BOTTLE'})
          .toLowerCase()}: </strong>{point.ReactivoEstadoBotella}<br/>
          <strong>${intl.formatMessage({id: 'HEADER.INITIAL_EVENT'})}: </strong>No<br/>`,
      },
      data: resultadosAceptados.map((resultado) => {
        const resultadoNivel = resultado.Niveles.find((nivel) => nivel.Numero === nivelGrafico)!
        const fechaPartes = resultado.Fecha.split(' ')[0].split('/')
        const anio = +fechaPartes[2]
        const mes = +fechaPartes[1]
        const dia = +fechaPartes[0]
        const valorZ = resultadoNivel.Z
        const valor = +resultadoNivel.Valor?.replace(',', '.')
        const estadoResultado =
          resultadoNivel.Estado == EstadoResultadoType.Aceptado
            ? intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
            : resultadoNivel.Estado == EstadoResultadoType.Rechazado
            ? intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})
            : intl.formatMessage({id: 'RESULT_STATUS.NOT_EVALUATED'})
        const estadoBotella =
          resultado.Reactivo.EstadoBotella == EstadoBotellaReactivoType.NoDisponible
            ? intl.formatMessage({id: 'REAGENT_BOTTLE.NOT_AVAILABLE'})
            : resultado.Reactivo.EstadoBotella == EstadoBotellaReactivoType.EnUso
            ? intl.formatMessage({id: 'REAGENT_BOTTLE.IN_USE'})
            : intl.formatMessage({id: 'REAGENT_BOTTLE.STAND_BY'})

        return {
          x: Date.UTC(anio, mes - 1, dia),
          y: valorZ > 4 ? 4 : valorZ < -4 ? -4 : valorZ,
          RealY: valorZ.toFixed(2),
          Id: resultado.Id,
          Fecha: resultado.Fecha,
          Valor: valor.toFixed(resultadoNivel.ValorDecimales),
          Z: valorZ.toFixed(2),
          ReactivoEstadoBotella: estadoBotella,
          Nivel: resultadoNivel.Numero,
          Estado: estadoResultado,
          marker: {
            symbol:
              valorZ > 4
                ? 'triangle'
                : valorZ < -4
                ? 'triangle-down'
                : formasNiveles[nivelGrafico - 1],
            radius: 5,
          },
        }
      }),
    })

    series.push({
      name: `${intl.formatMessage({id: 'SETTINGS.LEVEL'})} ${nivelGrafico} (${intl
        .formatMessage({
          id: 'RESULT_STATUS.REJECTED',
        })
        .toLowerCase()})`,
      type: 'scatter',
      lineWidth: 0,
      color: coloresNiveles[nivelGrafico - 1],
      tooltip: {
        headerFormat: undefined,
        pointFormat: `<strong>${intl.formatMessage({
          id: 'HEADER.DATE',
        })}: </strong>{point.Fecha}<br/>
          <strong>VI: </strong>{point.Valor}<br/>
          <strong>Z: </strong>{point.Z}<br/>
          <strong>${intl.formatMessage({id: 'HEADER.STATUS'})}: </strong>{point.Estado}<br/>
          <strong>${intl.formatMessage({id: 'HEADER.REAGENT'})} ${intl
          .formatMessage({id: 'SETTINGS.BOTTLE'})
          .toLowerCase()}: </strong>{point.ReactivoEstadoBotella}<br/>
          <strong>${intl.formatMessage({id: 'HEADER.INITIAL_EVENT'})}: </strong>No<br/>`,
      },
      marker: {
        symbol: formasNiveles[nivelGrafico - 1],
        fillColor: '#FFFFFF',
        lineWidth: 1,
        lineColor: '#F00',
      },
      data: resultadosRechazados.map((resultado) => {
        const resultadoNivel = resultado.Niveles.find((nivel) => nivel.Numero === nivelGrafico)!
        const fechaPartes = resultado.Fecha.split(' ')[0].split('/')
        const anio = +fechaPartes[2]
        const mes = +fechaPartes[1]
        const dia = +fechaPartes[0]
        const valorZ = resultadoNivel.Z
        const valor = +resultadoNivel.Valor?.replace(',', '.')
        const estadoResultado =
          resultadoNivel.Estado == EstadoResultadoType.Aceptado
            ? intl.formatMessage({id: 'RESULT_STATUS.ACCEPTED'})
            : resultadoNivel.Estado == EstadoResultadoType.Rechazado
            ? intl.formatMessage({id: 'RESULT_STATUS.REJECTED'})
            : intl.formatMessage({id: 'RESULT_STATUS.NOT_EVALUATED'})
        const estadoBotella =
          resultado.Reactivo.EstadoBotella == EstadoBotellaReactivoType.NoDisponible
            ? intl.formatMessage({id: 'REAGENT_BOTTLE.NOT_AVAILABLE'})
            : resultado.Reactivo.EstadoBotella == EstadoBotellaReactivoType.EnUso
            ? intl.formatMessage({id: 'REAGENT_BOTTLE.IN_USE'})
            : intl.formatMessage({id: 'REAGENT_BOTTLE.STAND_BY'})

        return {
          x: Date.UTC(anio, mes - 1, dia),
          y: valorZ > 4 ? 4 : valorZ < -4 ? -4 : valorZ,
          RealY: valorZ.toFixed(2),
          Id: resultado.Id,
          Fecha: resultado.Fecha,
          Valor: valor.toFixed(resultadoNivel.ValorDecimales),
          Z: valorZ.toFixed(2),
          ReactivoEstadoBotella: estadoBotella,
          Nivel: resultadoNivel.Numero,
          Estado: estadoResultado,
          marker: {
            symbol:
              valorZ > 4
                ? 'triangle'
                : valorZ < -4
                ? 'triangle-down'
                : formasNiveles[nivelGrafico - 1],
            radius: 5,
          },
        }
      }),
    })

    setOptions({
      chart: {
        type: 'line',
        marginRight: 60,
        height: 400,
      },
      title: {
        text: `${intl.formatMessage({id: 'SETTINGS.LEVEL'})} ${nivelGrafico}`,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d/%m/%Y}',
          rotation: -45,
        },
        plotLines: limitesGraficosControl.map((limite) => {
          const fechaPartes = limite.Fecha.split(' ')[0].split('/')
          const anio = +fechaPartes[2]
          const mes = +fechaPartes[1]
          const dia = +fechaPartes[0]
          return {
            color: '#361c32',
            dashStyle: 'Dash',
            value: Date.UTC(anio, mes - 1, dia),
            zIndex: 2,
            label: {
              text: intl.formatMessage({id: 'SETTINGS.REASSIGNMENT'}),
              style: {
                fontSize: '12px',
              },
            },
          }
        }),
      },
      yAxis: {
        title: {
          text: intl.formatMessage({id: 'HEADER.VALUE'}),
        },
        labels: {
          format: '{value} DE',
        },
        max: 4,
        min: -4,
        endOnTick: false,
        plotBands: [
          {
            color: '#65c29e',
            from: -2,
            to: 2,
          },
          {
            color: '#edd271',
            from: -3,
            to: -2,
          },
          {
            color: '#edd271',
            from: 2,
            to: 3,
          },
          {
            color: '#f58b7d',
            from: -4,
            to: -3,
          },
          {
            color: '#f58b7d',
            from: 3,
            to: 4,
          },
        ],
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: function () {
              return false
            },
          },
          states: {
            inactive: {
              opacity: 1,
            },
            hover: {
              enabled: false,
            },
          },
          marker: {
            enabled: true,
          },
        },
      },
      series: series,
    })
  }, [])

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default GraficoControlIDE
