import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'
import {ProcedimientoMedidaDTO} from '../../models/DTOs/procedimientos-de-medida/ProcedimientoMedidaDTO'

const getAllProcedimientosMedida = async () => {
  const {data} = await axios.get(ROUTES.PROCEDIMIENTOS_MEDIDA)

  return data
}

const useGetAllProcedimientosMedidaQuery = () => {
  return useQuery('useGetAllProcedimientosMedidaQuery', () => getAllProcedimientosMedida())
}

export {useGetAllProcedimientosMedidaQuery}
export {getAllProcedimientosMedida}
