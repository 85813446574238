import TablaEstadisticas from '../../components/cc-resultados/TablaEstadisticas'
import GraficoControlCC from '../../components/cc-resultados/graficos/GraficoControlCC'
import GraficoControlGeneral from '../../components/cc-resultados/graficos/GraficoControlGeneral'
import GraficoControlIDE from '../../components/cc-resultados/graficos/GraficoControlIDE'
import {ResumenPeriodoDTO} from '../../models/DTOs/cc-resultados/ResumenPeriodoDTO'
import {useIntl} from 'react-intl'
import {ResultadoDTO} from '../../models/DTOs/cc-resultados/ResultadoDTO'
import {LimiteGraficoControlDTO} from '../../models/DTOs/limites-de-graficos-de-control/LimiteGraficoControlDTO'
import {ProcMedidaWithEsquemaDTO} from '../../models/DTOs/reglas-de-control/ProcMedidaWithEsquemaDTO'
import {useInitPdf} from '../../helpers/PdfHelper'
import {Error404} from '../../components/errors/Error404'

const ReporteResultadosCCPDF = () => {
  const intl = useIntl()
  const {dataDecoded, hasError} = useInitPdf()

  if (hasError) return <Error404 />
  if (!dataDecoded) return <p>cargando...</p>

  const resultados: ResultadoDTO[] = dataDecoded.Resultados
  const niveles: number = dataDecoded.Niveles
  const regla: ProcMedidaWithEsquemaDTO = dataDecoded.Regla
  const limitesGraficosControl: LimiteGraficoControlDTO[] = dataDecoded.LimitesGraficosControl
  const tipoGrafico: string = dataDecoded.TipoGrafico
  const estadisticas: {Niveles: ResumenPeriodoDTO[]} = dataDecoded.Estadisticas

  const arrayNiveles: number[] = []
  for (let i = 1; i <= niveles; i++) {
    arrayNiveles.push(i)
  }

  document.body.style.background = '#FFF'

  return (
    <>
      <div className='d-flex justify-content-between mb-20'>
        <h1>{intl.formatMessage({id: 'HEADER.CONTROL_CHARTS'})}</h1>
        <h2>{tipoGrafico == 'cc' ? 'CC' : 'IDE'}</h2>
      </div>
      <div className='p-2'>
        {estadisticas && (
          <>
            {tipoGrafico === 'ide' ? (
              <>
                {arrayNiveles.map((nivel) => {
                  return (
                    <div
                      key={`grafico_control_nivel${nivel}`}
                      style={{marginBottom: '100px', pageBreakInside: 'avoid'}}
                    >
                      <GraficoControlIDE
                        resultados={resultados}
                        nivelGrafico={nivel}
                        limitesGraficosControl={limitesGraficosControl}
                      />

                      <TablaEstadisticas
                        estadisticas={
                          estadisticas.Niveles.find(
                            (estadistica: ResumenPeriodoDTO) => estadistica.Numero === nivel
                          )!
                        }
                      />
                    </div>
                  )
                })}
                <div style={{pageBreakInside: 'avoid'}}>
                  <GraficoControlGeneral
                    resultados={resultados}
                    arrayNiveles={arrayNiveles}
                    limitesGraficosControl={limitesGraficosControl}
                  />
                </div>
              </>
            ) : (
              arrayNiveles.map((nivel) => {
                return (
                  <div
                    key={`grafico_control_nivel${nivel}`}
                    style={{marginBottom: '100px', pageBreakInside: 'avoid'}}
                  >
                    <GraficoControlCC
                      resultados={resultados}
                      nivelGrafico={nivel}
                      limitesGraficosControl={limitesGraficosControl}
                      regla={regla}
                      limitesAsignados={
                        estadisticas.Niveles.find(
                          (estadistica: ResumenPeriodoDTO) => estadistica.Numero === nivel
                        )!.LimitesAsignados
                      }
                    />
                    <TablaEstadisticas
                      estadisticas={
                        estadisticas.Niveles.find(
                          (estadistica: ResumenPeriodoDTO) => estadistica.Numero === nivel
                        )!
                      }
                    />
                  </div>
                )
              })
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ReporteResultadosCCPDF
