import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {RolUsuario} from '../../../../app/models/DTOs/common/types'
import {useEffect, useState} from 'react'
import {showError} from '../../../../app/helpers/AlertHelper'
import {errorMessageBuilder} from '../../../../app/helpers/error-message-builder'
import {useGetProcedimientosMedidaQuery} from '../../../../app/modules/procedimientos-de-medida/get-procedimientos-medida'
import {SectorDTO} from '../../../../app/models/DTOs/sectores/SectorDTO'
import {ProcedimientoMedidaDTO} from '../../../../app/models/DTOs/procedimientos-de-medida/ProcedimientoMedidaDTO'
import {useAddLoteContext} from '../../../../app/components/lotes/AddLoteContext'
import {AsideMenuItemButton} from './AsideMenuItemButton'
import {MaterialDeControlDTO} from '../../../../app/models/DTOs/parametrizacion-general/materiales-de-control/MaterialDeControlDTO'
import {useGetMaterialesControlLaboratorioQuery} from '../../../../app/modules/parametrizacion-general/materiales-de-control/get-materiales-control-laboratorio'
import {LoteControlDTO} from '../../../../app/models/DTOs/parametrizacion-general/lotes/LoteControlDTO'

export function AsideMenuMain() {
  const [materialesControl, setMaterialesControl] = useState<MaterialDeControlDTO[]>([])
  const [procedimientosMedida, setProcedimientosMedida] = useState<ProcedimientoMedidaDTO[]>([])

  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const sectorActual: SectorDTO = useSelector<RootState>(
    ({auth}) => auth.sector,
    shallowEqual
  ) as SectorDTO

  const {openModal, setMaterialControl} = useAddLoteContext()

  const {
    isError: isMaterialesError,
    isSuccess: isMaterialesSuccess,
    isFetched: isMaterialesFetched,
    data: materialesData,
    error: materialesError,
  } = useGetMaterialesControlLaboratorioQuery()

  const {
    isError: isProcedimientosMedidaError,
    isSuccess: isProcedimientosMedidaSuccess,
    isFetched: isProcedimientosMedidaFetched,
    data: procedimientosMedidaData,
    error: procedimientosMedidaError,
  } = useGetProcedimientosMedidaQuery({sectorId: sectorActual.Id})

  useEffect(() => {
    if (!isMaterialesError && !isMaterialesSuccess) return

    if (isMaterialesSuccess && isMaterialesFetched && materialesData) {
      setMaterialesControl(materialesData)
    } else if (isMaterialesError) {
      showError(
        'Error',
        errorMessageBuilder(materialesError, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [isMaterialesError, isMaterialesFetched, isMaterialesSuccess, materialesData])

  useEffect(() => {
    if (!isProcedimientosMedidaError && !isProcedimientosMedidaSuccess) return

    if (
      isProcedimientosMedidaSuccess &&
      isProcedimientosMedidaFetched &&
      procedimientosMedidaData
    ) {
      setProcedimientosMedida(procedimientosMedidaData)
    } else if (isProcedimientosMedidaError) {
      showError(
        'Error',
        errorMessageBuilder(procedimientosMedidaError, intl.formatMessage),
        intl.formatMessage({id: 'SETTINGS.ACCEPT'})
      )
    }
  }, [
    isProcedimientosMedidaError,
    isProcedimientosMedidaFetched,
    isProcedimientosMedidaSuccess,
    procedimientosMedidaData,
  ])

  const addLoteHandler = (material: MaterialDeControlDTO) => {
    setMaterialControl(material)
    openModal()
  }

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {user.Rol.Name === RolUsuario.AdministradorLaboratorio && (
        <>
          {materialesControl
            .filter((material) =>
              material.Lotes?.find((lote: LoteControlDTO) =>
                lote.ProcedimientosMedida?.find((proc) =>
                  procedimientosMedida.find((procedimiento) => procedimiento.Id === proc)
                )
              )
            )
            .map((material) => {
              return (
                <div key={`aside_materiales_${material.Id}`}>
                  <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                        {material.Nombre}
                      </span>
                    </div>
                  </div>
                  {material.Lotes!.map((lote: LoteControlDTO) => {
                    return (
                      <AsideMenuItem
                        id={lote.LoteId.toString()}
                        icon='/media/icons/duotune/medicine/med004.svg'
                        title={lote.Numero}
                        fontIcon='bi-app-indicator'
                        to={`/resultados/materialControl/${material.Id}/lote/${lote.LoteId}`}
                        key={`aside_materiales_${material.Id}_lotes_${lote.LoteId}`}
                      />
                    )
                  })}
                  {/* <AsideMenuItemButton
                    title={intl.formatMessage({id: 'HEADER.ADD_BATCH'})}
                    fontIcon='bi-app-indicator'
                    icon='/media/icons/duotune/general/gen035.svg'
                    onClick={() => addLoteHandler(material)}
                  /> */}
                </div>
              )
            })}
        </>
      )}
    </>
  )
}
