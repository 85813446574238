import {FC} from 'react'
import {useIntl} from 'react-intl'
import {ResumenPeriodoDTO} from '../../models/DTOs/cc-resultados/ResumenPeriodoDTO'

interface Props {
  estadisticas: ResumenPeriodoDTO
}

const TablaEstadisticas: FC<Props> = ({estadisticas}) => {
  const intl = useIntl()

  return (
    <div className='d-flex justify-content-center mb-8 mt-4'>
      <div className='table-responsive col-6'>
        <table className='table table-bordered table-row-bordered align-middle gs-3 gy-3'>
          <thead style={{backgroundColor: '#f5f8ff', verticalAlign: 'middle', textAlign: 'center'}}>
            <tr className='fw-bolder'>
              <th style={{width: '150px', verticalAlign: 'middle'}}></th>
              <th style={{width: '100px', verticalAlign: 'middle'}}>
                {intl.formatMessage({id: 'HEADER.EVALUATED_PERIOD'})}
              </th>
              <th style={{width: '100px', verticalAlign: 'middle'}}>
                {intl.formatMessage({id: 'HEADER.ASSIGNED_LIMITS'})}
              </th>
              <th style={{width: '100px', verticalAlign: 'middle'}}>
                {intl.formatMessage({id: 'HEADER.ACCUMULATED'})}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{height: '30px'}}>
              <td className='text-muted fw-bolder text-center'>
                {intl.formatMessage({id: 'HEADER.MEAN'})}
              </td>
              <td className='text-right'>{estadisticas?.Periodo.Mean.toFixed(2)}</td>
              <td className='text-right'>
                {estadisticas?.LimitesAsignados?.ValorObjetivo?.Value.toFixed(
                  estadisticas.LimitesAsignados?.ValorObjetivo.Precision
                )}
              </td>
              <td className='text-right'>{estadisticas?.Acumulado.Mean.toFixed(2)}</td>
            </tr>
            <tr style={{height: '30px'}}>
              <td className='text-muted fw-bolder text-center'>
                {intl.formatMessage({id: 'HEADER.SD'})}
              </td>
              <td className='text-right'>{estadisticas?.Periodo.StdDev.toFixed(2)}</td>
              <td className='text-right'>
                {estadisticas?.LimitesAsignados?.StdDev?.Value.toFixed(
                  estadisticas.LimitesAsignados?.StdDev.Precision
                )}
              </td>
              <td className='text-right'>{estadisticas?.Acumulado.StdDev.toFixed(2)}</td>
            </tr>
            <tr style={{height: '30px'}}>
              <td className='text-muted fw-bolder  text-center'>%CV</td>
              <td className='text-right'>{estadisticas?.Periodo.CV.toFixed(1)}</td>
              <td className='text-right'>{estadisticas?.LimitesAsignados?.CV.toFixed(1)}</td>
              <td className='text-right'>{estadisticas?.Acumulado.CV.toFixed(1)}</td>
            </tr>
            <tr style={{height: '30px'}}>
              <td className='text-muted fw-bolder text-center'>
                {intl.formatMessage({id: 'HEADER.ACCEPTED_DATA'})}
              </td>
              <td className='text-right'>{estadisticas?.Periodo.DatosAceptados}</td>
              <td className='text-right'></td>
              <td className='text-right'>{estadisticas?.Acumulado.DatosAceptados}</td>
            </tr>
            <tr style={{height: '30px'}}>
              <td className='text-muted fw-bolder text-center'>
                {intl.formatMessage({id: 'HEADER.REJECTED_DATA'})}
              </td>
              <td className='text-right'>{estadisticas?.Periodo.DatosRechazados}</td>
              <td className='text-right'></td>
              <td className='text-right'>{estadisticas?.Acumulado.DatosRechazados}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TablaEstadisticas
