import axios from 'axios'
import ROUTES from '../../helpers/Routes'
import {useQuery} from 'react-query'

interface Props {
  sectorId: number
}

const getProcedimientosMedida = async ({sectorId}: Props) => {
  const {data} = await axios.get(`${ROUTES.PROCEDIMIENTOS_MEDIDA}/sectores/${sectorId}`)

  return data
}

const useGetProcedimientosMedidaQuery = (input: Props) => {
  return useQuery('useGetProcedimientosMedidaQuery', () => getProcedimientosMedida(input))
}

export {useGetProcedimientosMedidaQuery}
