import axios from 'axios'
import ROUTES from '../../helpers/Routes'

interface Props {
  url: string
}

const getPDF = async ({url}: Props) => {
  const {data} = await axios.get(ROUTES.PDF_OLD, {params: {url}})

  return data
}

export {getPDF}
