import axios from 'axios'
import ROUTES from '../../../helpers/Routes'
import {useMutation} from 'react-query'

interface Props {
  loteId?: number
  materialId: number
  numero: string
  fechaVencimiento: string
  procedimientosId: number[]
  isActivo: boolean
}

const upsertLote = async ({
  fechaVencimiento,
  materialId,
  numero,
  loteId,
  procedimientosId,
  isActivo,
}: Props) => {
  return await axios.post(ROUTES.MATERIALES_CONTROL_LOTES.replace(':id', materialId.toString()), {
    Id: loteId,
    Numero: numero,
    Vencimiento: fechaVencimiento,
    ProcedimientosMedida: procedimientosId,
    IsActivo: isActivo,
  })
}

const useUpsertLoteMutation = () => {
  return useMutation('useUpsertLoteMutation', (input: Props) => upsertLote(input))
}

export {useUpsertLoteMutation}
